import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { LinkText } from '../../components/LinkText';
import { Page } from '../../components/ui/Page';
import { config } from '../../config';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { useLoggedIn } from '../../hooks/apollo/useLoggedIn';
import { studioRoutes } from '../../router/constants';

export const ErrorNetworkProblems = ({ refresh }) => {
    const { t } = useTranslation();
    const isLoggedIn = useLoggedIn();

    /**
     * This is fallback because sometimes it fails from some weird reason that CORS headers are sent before
     */
    useEffect(() => {
        // we want to catch all errors in localhost and do not refresh in development
        if (process.env.NODE_ENV === 'production') {
            const hasRefreshedCount = JSON.parse(window.sessionStorage.getItem('error-refreshed-count') || '0');

            try {
                const hasRefreshedCountNumberValue = Number(hasRefreshedCount);
                if (hasRefreshedCountNumberValue === 0) {
                    window.sessionStorage.setItem('error-refreshed-count', '1'); // we are now going to refresh
                    window.location.reload(); // refresh the page
                }
            } catch (err) {}

            setTimeout(function () {
                // clear from sessionStorage after 5 minutes
                window.sessionStorage.removeItem('error-refreshed-count');
            }, 300000);
        }
    }, []);

    /*
    https://www.google.com/search?q=funny+design+500+error+page&tbm=isch&ved=2ahUKEwianIXylc7yAhXXNOwKHVe0CFMQ2-cCegQIABAA&oq=funny+design+500+error+page&gs_lcp=CgNpbWcQA1CHqQJYhawCYNGwAmgAcAB4AYABigOIAaQGkgEHMC4xLjEuMZgBAKABAaoBC2d3cy13aXotaW1nwAEB&sclient=img&ei=iUMnYdoz1-mwB9foopgF&bih=1304&biw=2560&rlz=1C5CHFA_enCZ915CZ915#imgrc=_d3BzR00C0grbM
     */

    return (
        <Page headerTitle={t('errorNs:errorPage.somethingWentWrongTitle')}>
            <p>{t('errorNs:errorPage.somethingWentWrong1')}</p>
            <p>{t('errorNs:errorPage.somethingWentWrong2')}</p>
            {isLoggedIn ? (
                <p>
                    <Trans
                        i18nKey={refresh ? 'errorNs:errorPage.tryToRefreshPage' : 'errorNs:errorPage.tryToLoad'}
                        components={{
                            dashboardLink: (
                                <LinkText
                                    to={`/${APPLICATION_SUBDOMAIN}/`}
                                    onClick={() => (window.location.href = `/${APPLICATION_SUBDOMAIN}/`)}
                                />
                            ),
                        }}
                    />
                </p>
            ) : (
                <p>
                    {refresh ? (
                        <p>
                            <Trans
                                i18nKey="errorNs:errorPage.tryToRefreshPageOr"
                                components={{
                                    frontendHostLink: <LinkText to={config.FRONTEND_HOST} />,
                                }}
                            />
                        </p>
                    ) : (
                        <a href={config.FRONTEND_HOST}>{t('errorNs:errorPage.goToHome')}</a>
                    )}
                </p>
            )}
        </Page>
    );
};
