import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/images/zenamuLogoWithIcon.webp';

interface Props {
    color: string;
    width: number;
}

const NavbarLogoContainer = styled.a<{ imgUrl: string; imgUrlHover: string; width: number }>`
    width: 53.48px;
    height: ${({ width }) => `${width}px`};
    background-image: ${({ imgUrl }) => `url(${imgUrl})`};
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain;
    transition: all 0.4s ease; /* Add a transition for smooth animation */
    -webkit-transition: all 0.4s ease;
    display: inline-block;

    :hover {
        background-image: ${({ imgUrlHover }) => `url(${imgUrlHover})`};
        text-decoration: none;
        transform: rotate(-5deg); /* Rotate the image by 25 degrees on hover */
    }
`;

type LogoProps = {
    width?: number;
    color?: string;
};

export const Logo = ({ width = 40, color = 'black' }: LogoProps) => {
    return (
        <NavbarLogoContainer
            width={width}
            imgUrl={logo}
            imgUrlHover={logo}
            href="https://zenamu.com"
            target="_blank"
            rel="noreferrer"
            title="Zenamu"
        />
    );
};
