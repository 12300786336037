import { Progress } from 'antd';
import React from 'react';

import i18n from '../../lib/i18n';

const getGradientStrokeColor = (percent: number): { direction: string; [key: string]: string } => {
    if (percent <= 60) {
        return {
            direction: 'to right',
            '0%': '#52c41a',
            '100%': '#faad14',
        };
    } else if (percent <= 75) {
        return {
            direction: 'to right',
            '0%': '#faad14',
            '100%': '#faad14',
        };
    } else if (percent <= 90) {
        return {
            direction: 'to right',
            '0%': '#faad14',
            '50%': '#fa5414',
            '100%': '#f5222d',
        };
    } else if (percent < 100) {
        return {
            direction: 'to right',
            '0%': '#fa5414',
            '50%': '#f5222d',
            '100%': '#f5222d',
        };
    } else {
        return {
            direction: 'to right',
            '0%': '#f5222d',
            '100%': '#f5222d',
        };
    }
};

const calculatePercent = (current: number, max: number) => {
    if (max === 0) return 0;
    return (current / max) * 100;
};

export const getFormattedLimit = (current: number, max: number, withTranslationPlural = true) => {
    const ordersRemainingTranslation = i18n.t('plurals.order', {
        postProcess: 'interval',
        count: max,
    });

    return (
        <span style={{ color: '#7e7d7d' }}>
            {current}/{max} {withTranslationPlural && ordersRemainingTranslation}
        </span>
    );
};

const GradientProgress = ({ current, max, type }: { current: number; max: number; type: 'circle' | 'line' }) => {
    const percent = calculatePercent(current, max);

    return (
        <Progress
            type={type}
            percent={percent}
            strokeColor={getGradientStrokeColor(percent)}
            format={() => <>{getFormattedLimit(current, max, type !== 'circle')}</>}
            width={type === 'circle' ? 60 : undefined}
        />
    );
};

interface MaxOrdersProgressCircleProps {
    current: number;
    max: number;
    type?: 'circle' | 'line';
}

export const MaxOrdersProgressCircle = ({ current, max, type = 'line' }: MaxOrdersProgressCircleProps) => {
    return <GradientProgress current={current} max={max} type={type} />;
};
