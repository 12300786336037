import { useMutation } from '@apollo/client';
import { fyoogaMutations } from '@fyooga/graphql-requests';
import { Dropdown, MenuProps } from 'antd';
import i18next from 'i18next';
import React, { useMemo, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';

import { appLocales } from '../constants';
import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { UserMenuWrapper } from '../layout/menu/UserProfileDropdown';
import { setAppLocale } from '../lib/apollo/cache';
import { handleApolloServerRequestErrors } from '../lib/apollo/errors';
import { getLanguageCodeByCountryCode } from '../util/language';
import { Caret } from './ui/Caret';
import { ContentLoading } from './ui/ContentLoading';

export const LanguageSwitcher = ({ userId = null }: { userId?: number | null }) => {
    const userAppLocale = useAppLocale();
    const [isActive, setIsActive] = useState(false);
    const [switchingLanguageLoading, setSwitchingLanguageLoading] = useState(false);
    const [callUpdateUserById] = useMutation(fyoogaMutations.updateUserById);

    const storeLanguageLocally = async (lang: string) => {
        await i18next.changeLanguage(lang);
        setAppLocale(lang);
        // window.location.reload(); // refresh the browser to prevent cached values (ZEN-436)
    };

    const languagesMenuItems = useMemo(() => {
        let items = {
            [appLocales.EN]: {
                key: appLocales.EN,
                label: 'English',
            },
            [appLocales.ES]: {
                key: appLocales.ES,
                label: 'Español',
            },
            [appLocales.CS]: {
                key: appLocales.CS,
                label: 'Čeština',
            },
            [appLocales.SK]: {
                key: appLocales.SK,
                label: 'Slovenčina',
            },
            [appLocales.HU]: {
                key: appLocales.HU,
                label: 'Magyar',
            },
            [appLocales.PL]: {
                key: appLocales.PL,
                label: 'Polski',
            },
            [appLocales.PT]: {
                key: appLocales.PT,
                label: 'Português (BR)',
            },
        };

        return items;
    }, []);

    const languagesMenu = useMemo(() => {
        const itemsSize = Object.keys(languagesMenuItems).length;

        if (itemsSize > 0) {
            const itemsGroup: MenuProps['items'] = [];
            for (let itemKey in languagesMenuItems) {
                // @ts-ignore
                const item = languagesMenuItems[itemKey];
                const { key, label } = item;
                if (key === 'divider') {
                    itemsGroup.push({
                        type: 'divider',
                    });
                } else {
                    itemsGroup.push({
                        key,
                        label,
                    });
                }
            }
            return itemsGroup;
        }
        return [];
    }, [languagesMenuItems]);

    const switchLanguage = async (value: string) => {
        if (userId) {
            try {
                await callUpdateUserById({
                    variables: {
                        userId,
                        input: {
                            lang: value,
                        },
                    },
                });
            } catch (err: any) {
                handleApolloServerRequestErrors(err);
            } finally {
                setSwitchingLanguageLoading(false);
            }
        }
        await storeLanguageLocally(value);
    };

    return (
        <UserMenuWrapper>
            <Dropdown
                trigger={['click']}
                placement="bottomRight"
                onOpenChange={visible => setIsActive(visible)}
                menu={{
                    items: languagesMenu,
                    onClick: async event => {
                        setIsActive(false);
                        setSwitchingLanguageLoading(true);
                        const { key } = event;
                        await switchLanguage(key);
                    },
                    selectable: true,
                }}
                overlayStyle={{
                    minWidth: '200px',
                }}
            >
                <div className="userMenu-profile">
                    {!switchingLanguageLoading ? (
                        <>
                            <span className="language__name">
                                {getLanguageCodeByCountryCode(userAppLocale)}
                                {/*
                                <CircleFlag
                                    countryCode={getLanguageFlagByCountryCode(userAppLocale)}
                                    height="20"
                                    width={20}
                                    alt={getLanguageFlagByCountryCode(userAppLocale)}
                                />
                                */}
                            </span>
                            <Caret isActive={isActive} />
                        </>
                    ) : (
                        <span className="language__name">
                            <ContentLoading />
                        </span>
                    )}
                </div>
            </Dropdown>
        </UserMenuWrapper>
    );
};
