import { CopyOutlined, EyeOutlined, MedicineBoxOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useMutation, useReactiveVar } from '@apollo/client';
import { officeUserRoles } from '@fyooga/codebook';
import { fyoogaMutations } from '@fyooga/graphql-requests';
import { MaskStylesObj } from '@reactour/mask';
import { PopoverStylesObj } from '@reactour/popover';
import { StepType, StylesObj } from '@reactour/tour';
import { addDays, isBefore, isEqual, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import React, { Dispatch, ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from '../components/basics/Icon/Icon';
import { InteractiveButton } from '../components/buttons';
import { NewTariffsModal } from '../components/ui/NewTariffsModal';
import { APPLICATION_SUBDOMAIN, NEW_TARIFFS_START_DATE_STRING } from '../constants';
import history from '../history';
import { useEmployeeCurrentHandledOfficeData } from '../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { useHasUserAccessByOfficeRole } from '../hooks/useHasUserAccessByOfficeRole';
import { newTariffInfoSeenVar, setEmployeeCurrentHandledOrganisationOffice } from '../lib/apollo/cache';
import { handleApolloServerRequestErrors } from '../lib/apollo/errors';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_ENTRANCE,
    SETTINGS_TAB_KEY_GENERAL,
    SETTINGS_TAB_KEY_PAYMENTS,
    SETTINGS_TAB_KEY_RESERVATIONS,
    TRIAL_TARIFF_DAYS_DURATION,
} from '../router/constants';
import { borderRadiusBase, borderRadiusBaseNumber, primaryColor, secondaryColor, sidebarItemIcon } from '../theme';
import { isTariffTrial } from '../util/tariff';

export type ZenamuTourContextType = {
    isPathnameForDefaultTour: boolean;
    isPathnameForSettingsTour: boolean;
    isShownTourButtonForSuperadmin: boolean;
    loading: boolean;
    tourDefaultSteps: StepType[];
    tourSettingsSteps: StepType[];
    tourStyle: StylesObj & PopoverStylesObj & MaskStylesObj;
    handleClickOnMaskOrCloseButton: (
        type: 'default' | 'settings',
        domain: string,
        steps: StepType[] | undefined,
        currentStep: number,
        setIsOpen: Dispatch<React.SetStateAction<boolean>>,
        setCurrentStep: Dispatch<React.SetStateAction<number>>,
        actionOnClose?: () => void,
    ) => void;
    actionOnClose?: () => void;
    handleUpdateProductTourEmployeeStatus: (
        officeId: number,
        employeeId: number,
        productTourCompleted: boolean,
        redirectToSettings?: boolean,
    ) => void;
};

export const ZenamuTourContext = createContext<ZenamuTourContextType>({
    isPathnameForDefaultTour: false,
    isPathnameForSettingsTour: false,
    isShownTourButtonForSuperadmin: false,
    loading: true,
    tourDefaultSteps: [],
    tourSettingsSteps: [],
    tourStyle: {},
    handleClickOnMaskOrCloseButton: () => {},
    handleUpdateProductTourEmployeeStatus: () => {},
});

export const ZenamuTourProvider = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData();
    const newTariffInfoSeen = useReactiveVar(newTariffInfoSeenVar);
    const [newTariffInfShowModal, setNewTariffInfShowModal] = useState(false);

    const [callUpdateOfficeEmployee] = useMutation(fyoogaMutations.updateOfficeEmployeeById);

    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(employeeCurrentHandledOfficeData?.officeId, [officeUserRoles.SUPERADMIN]);

    useEffect(() => {
        const now = new Date();
        const newTariffsStartDate = zonedTimeToUtc(NEW_TARIFFS_START_DATE_STRING, 'Europe/Prague');
        const isBeforeOrEqual = isBefore(now, newTariffsStartDate) || isEqual(now, newTariffsStartDate);

        // @ts-ignore
        const newTariffInfoAlreadySeen = newTariffInfoSeen === true || newTariffInfoSeen === '1';

        if (
            !loadingCanUserAccessSettingsForSuperadminRole &&
            employeeCurrentHandledOfficeData &&
            canUserAccessSettingsForSuperadminRole &&
            !newTariffInfoAlreadySeen
        ) {
            if (isBeforeOrEqual) {
                setNewTariffInfShowModal(true);
            }
        }
    }, [
        newTariffInfoSeen,
        employeeCurrentHandledOfficeData,
        loadingCanUserAccessSettingsForSuperadminRole,
        canUserAccessSettingsForSuperadminRole,
    ]);

    const contextConfig = useMemo(() => {
        if (!loadingCanUserAccessSettingsForSuperadminRole && employeeCurrentHandledOfficeData) {
            const builtUrlForDefaultTour = `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}`;
            // const builtUrlForSettingsTour = `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`;

            const tariffName = employeeCurrentHandledOfficeData?.organisationTariff?.name ?? 'UNKNOWN';
            const isTrial = isTariffTrial(tariffName);

            const isSuperadminAndCanAccess = canUserAccessSettingsForSuperadminRole;

            const isShownTourButtonForSuperadmin = !isMobile && isTrial && isSuperadminAndCanAccess;

            const isProductTourHiddenForEmployee =
                !canUserAccessSettingsForSuperadminRole ||
                (isSuperadminAndCanAccess && employeeCurrentHandledOfficeData?.productTourCompleted);

            const isPathnameForDefaultTour =
                !isMobile &&
                !isProductTourHiddenForEmployee &&
                (pathname === builtUrlForDefaultTour || pathname === `${builtUrlForDefaultTour}/calendar`);

            const isPathnameForSettingsTour =
                !isMobile &&
                !isProductTourHiddenForEmployee &&
                pathname.includes(
                    `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/`,
                );

            return {
                isPathnameForDefaultTour,
                isPathnameForSettingsTour,
                isShownTourButtonForSuperadmin,
                loading: loadingCanUserAccessSettingsForSuperadminRole,
            };
        }

        return {
            isPathnameForDefaultTour: false,
            isPathnameForSettingsTour: false,
            isShownTourButtonForSuperadmin: false,
            loading: loadingCanUserAccessSettingsForSuperadminRole,
        };
    }, [
        employeeCurrentHandledOfficeData,
        loadingCanUserAccessSettingsForSuperadminRole,
        canUserAccessSettingsForSuperadminRole,
        pathname,
    ]);

    const handleClickOnMaskOrCloseButton = (
        type: 'default' | 'settings',
        domain: string,
        steps: StepType[] | undefined,
        currentStep: number,
        setIsOpen: Dispatch<React.SetStateAction<boolean>>,
        setCurrentStep: Dispatch<React.SetStateAction<number>>,
        actionOnClose?: () => void,
    ) => {
        if (steps) {
            if (currentStep === steps.length - 1) {
                setIsOpen(false);
                if (actionOnClose) {
                    actionOnClose();
                }
            }
            setCurrentStep(s => (s === steps.length - 1 ? 0 : s + 1));
        }
    };

    const handleUpdateProductTourEmployeeStatus = async (
        officeId: number,
        employeeId: number,
        productTourCompleted: boolean,
        redirectToSettings?: boolean,
    ) => {
        try {
            const updatedEmployeeData: any = await callUpdateOfficeEmployee({
                variables: {
                    officeId,
                    employeeId,
                    input: {
                        productTourCompleted,
                    },
                },
            });
            if (updatedEmployeeData?.data?.updateOfficeEmployeeById?.productTourCompleted) {
                setEmployeeCurrentHandledOrganisationOffice({
                    ...employeeCurrentHandledOfficeData,
                    productTourCompleted: updatedEmployeeData.data.updateOfficeEmployeeById.productTourCompleted,
                });
            }
            if (productTourCompleted && redirectToSettings) {
                window.scrollTo(0, 0);
                history.push(
                    `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`,
                );
            }
        } catch (err: any) {
            handleApolloServerRequestErrors(err);
        }
    };

    const getCancelGuideButton = (setIsOpen: any) => {
        return (
            <InteractiveButton
                type="default"
                danger
                block
                value={t('header.quitGuide')}
                onClick={async () => {
                    await handleUpdateProductTourEmployeeStatus(
                        employeeCurrentHandledOfficeData.officeId,
                        employeeCurrentHandledOfficeData.organisationEmployeeId,
                        true,
                    );
                    setIsOpen(false);
                }}
            />
        );
    };

    return (
        <ZenamuTourContext.Provider
            value={{
                ...contextConfig,
                tourDefaultSteps: [
                    {
                        selector: '.reactour-default-new-lecture-buttons-assistance',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('header.tour.welcomeToZenamu.title')}</h2>
                                <p>{t('header.tour.welcomeToZenamu.part1')}</p>
                                {isBefore(
                                    new Date(),
                                    addDays(
                                        parseISO(employeeCurrentHandledOfficeData?.registrationDate),
                                        TRIAL_TARIFF_DAYS_DURATION,
                                    ),
                                ) && (
                                    <p>
                                        <Trans
                                            i18nKey="header.tour.welcomeToZenamu.part3"
                                            components={{
                                                helpIcon: <MedicineBoxOutlined />,
                                            }}
                                            values={{
                                                helpText: t('header.getAssistance'),
                                            }}
                                        />
                                    </p>
                                )}
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                    },
                    {
                        selector: '.reactour-default-new-lecture-buttons-new',
                        content: ({ setIsOpen }) => (
                            <>
                                <h3>
                                    <b>{t('header.tour.welcomeToZenamu.warning')}</b>
                                </h3>
                                <p>
                                    <Trans
                                        i18nKey="header.tour.welcomeToZenamu.part2"
                                        components={{
                                            createTermIcon: <PlusCircleOutlined />,
                                        }}
                                        values={{
                                            createNewTermText: t('lectureNs:lectureTermForm.createTerm'),
                                        }}
                                    />
                                </p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                    },
                    {
                        selector: '.reactour-default-calendar-eye',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('calendarNs:calendar.publicStudioCalendar')}</h2>
                                <p>
                                    <Trans
                                        i18nKey="header.tour.publicSchedule.part1"
                                        components={{
                                            eyeIcon: <EyeOutlined />,
                                        }}
                                    />
                                </p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                    },
                    {
                        selector: '.reactour-default-calendar-item',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('sidebar.calendar')}</h2>
                                <p>
                                    <Trans
                                        i18nKey="header.tour.calendarMenu.part1"
                                        components={{
                                            calendarIcon: <Icon icon="Calendar1" size={20} iconColor={primaryColor} />,
                                        }}
                                    />
                                </p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                    },
                    {
                        selector: '.reactour-default-settings-item',
                        content: (
                            <>
                                <h2>{t('sidebar.settings')}</h2>
                                <p>
                                    <Trans
                                        i18nKey="header.tour.settingsMenu.part1"
                                        components={{
                                            settingsIcon: (
                                                <Icon icon="SettingsGears" size={20} iconColor={primaryColor} />
                                            ),
                                        }}
                                        values={{
                                            settingsTitle: t('sidebar.settings'),
                                        }}
                                    />
                                </p>
                                <p>{t('header.tour.settingsMenu.part2')}</p>
                                <InteractiveButton
                                    type="default"
                                    block
                                    value={t('header.tour.settingsMenu.ctaButton')}
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        history.push(
                                            `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`,
                                        );
                                    }}
                                />
                            </>
                        ),
                    },
                ],
                tourSettingsSteps: [
                    {
                        selector: '.reactour-settings-step',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('settingsNs:info.title')}</h2>
                                <p>{t('header.tour.settingsPublicSchedule.part1')}</p>
                                <p>{t('header.tour.settingsPublicSchedule.part2')}</p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                        action: element => {
                            history.push(
                                `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`,
                            );
                        },
                    },
                    {
                        selector: '.reactour-settings-public-schedule',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('settingsNs:info.registrationDomain')}</h2>
                                <p>
                                    <Trans
                                        i18nKey="header.tour.settingsPublicSchedule.part3"
                                        components={{
                                            copyIcon: <CopyOutlined />,
                                        }}
                                    />
                                </p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                        action: element => {
                            history.push(
                                `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`,
                            );
                        },
                    },
                    {
                        selector: '.reactour-payments-step',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('settingsNs:payments.paymentMethods.subtitle')}</h2>
                                <p>{t('header.tour.settingsPayments.part1')}</p>
                                <p>{t('header.tour.settingsPayments.part2')}</p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                        action: element => {
                            history.push(
                                `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_PAYMENTS}`,
                            );
                        },
                    },
                    {
                        selector: '.reactour-bundles-step',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('settingsNs:creditsAndPass.sectionTitle')}</h2>
                                <p>{t('header.tour.settingsBundles.part1')}</p>
                                <p>{t('header.tour.settingsBundles.part2')}</p>
                                {getCancelGuideButton(setIsOpen)}
                            </>
                        ),
                        action: element => {
                            history.push(
                                `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_ENTRANCE}`,
                            );
                        },
                    },
                    {
                        selector: '.reactour-templates-step',
                        content: ({ setIsOpen }) => (
                            <>
                                <h2>{t('header.tour.settingsFinish.title')}</h2>
                                <p>{t('header.tour.settingsFinish.part1')}</p>
                                <p>{t('header.tour.settingsFinish.part2')}</p>
                                <InteractiveButton
                                    type="default"
                                    block
                                    value={t('header.tour.settingsFinish.ctaButton')}
                                    onClick={() => {
                                        handleUpdateProductTourEmployeeStatus(
                                            employeeCurrentHandledOfficeData.officeId,
                                            employeeCurrentHandledOfficeData.organisationEmployeeId,
                                            true,
                                            true,
                                        );
                                        window.scrollTo(0, 0);
                                        history.push(
                                            `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_GENERAL}`,
                                        );
                                        setIsOpen(false);
                                    }}
                                />
                            </>
                        ),
                        action: element => {
                            history.push(
                                `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData?.domain}/settings/${SETTINGS_TAB_KEY_RESERVATIONS}`,
                            );
                        },
                    },
                ],
                tourStyle: {
                    maskArea: base => ({ ...base, rx: borderRadiusBaseNumber }),
                    controls: base => ({ ...base, marginTop: 50 }),
                    maskWrapper: base => ({ ...base, color: 'rgba(0, 0, 0, 0.45)' }),
                    popover: base => ({
                        ...base,
                        '--reactour-accent': secondaryColor,
                        borderRadius: borderRadiusBase,
                        paddingTop: 35,
                    }),
                },
                handleClickOnMaskOrCloseButton,
                handleUpdateProductTourEmployeeStatus,
            }}
        >
            {children}
            <NewTariffsModal visible={newTariffInfShowModal} onCloseModal={() => setNewTariffInfShowModal(false)} />
        </ZenamuTourContext.Provider>
    );
};
