import { useEffect, useState } from 'react';

import { ZENAMU_LANDING_PAGE_HOSTNAME, appLocales } from '../constants';
import { useAppLocale } from './apollo/useAppLocale';

export const useLocalizedZenamuLandingPageHost = () => {
    const userAppLocale = useAppLocale();
    const [hostname, setHostname] = useState(ZENAMU_LANDING_PAGE_HOSTNAME);

    useEffect(() => {
        switch (userAppLocale) {
            case appLocales.CS:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/cs`);
                break;
            case appLocales.SK:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/sk`);
                break;
            case appLocales.HU:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/hu`);
                break;
            case appLocales.PL:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/pl`);
                break;
            case appLocales.ES:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/es`);
                break;
            case appLocales.PT:
                setHostname(`${ZENAMU_LANDING_PAGE_HOSTNAME}/pt-BR`);
                break;
            case appLocales.EN: {
                setHostname(ZENAMU_LANDING_PAGE_HOSTNAME);
                break;
            }
            default: {
                setHostname(ZENAMU_LANDING_PAGE_HOSTNAME);
                break;
            }
        }
    }, [userAppLocale]);

    return hostname;
};
