import React from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { APPLICATION_SUBDOMAIN } from '../../constants';
import { APP_TAB_KEY_OFFICE } from '../../router/constants';

interface CurrentStudioNameProps {
    studioName: string;
    studioDomain: string;
}

export const CurrentStudioName = ({ studioName, studioDomain }: CurrentStudioNameProps) => {
    return (
        <>
            {!isMobile && <div className="header-separator" />}
            <Link
                to={{
                    pathname: `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${studioDomain}`,
                }}
            >
                <div className="current-studio-name">{studioName}</div>
            </Link>
        </>
    );
};
