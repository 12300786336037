import { config } from '../config';

// FIXME: this is totally MVP but works for now
export const isVisibleSystemSuperadminMenu = (allowedUserEmail: string) => {
    const systemSuperadminEmailsString = config.SYSTEM_SUPERADMIN_IDS;
    if (systemSuperadminEmailsString) {
        const systemSuperadminEmailsStringArray = systemSuperadminEmailsString.split(',');
        const emails = systemSuperadminEmailsStringArray.map(superadminEmail => superadminEmail.trim());
        return emails.includes(allowedUserEmail);
    }
    return false;
};
