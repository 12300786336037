import { appLanguages } from '@fyooga/codebook';

import { appLocales } from '../constants';
import LOCAL_STORAGE_KEYS from '../enums/localStorageKeys';

export const getLanguage = fallbackLanguage => {
    let localizedLanguage;
    if (typeof navigator !== 'undefined') {
        const lang = navigator.language || navigator.userLanguage;
        const trimmedLang = lang.trim();

        localizedLanguage = trimmedLang.split(/-|_/)[0];
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('cs')) {
        return appLanguages.CS;
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('sk')) {
        return appLanguages.SK;
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('hu')) {
        return appLanguages.HU;
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('pl')) {
        return appLanguages.PL;
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('es')) {
        return appLanguages.ES;
    }
    if (localizedLanguage && localizedLanguage.toLowerCase().startsWith('pt')) {
        return appLanguages.PT;
    }
    return fallbackLanguage;
};

export const initAppLocale = fallbackLanguage => {
    // if there is appLocale stored in local storage, just return it
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LOCALE)) {
        return localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LOCALE);
    }

    // try to find locale from browser
    const appLocale = getLanguage(fallbackLanguage);
    localStorage.setItem(LOCAL_STORAGE_KEYS.APP_LOCALE, appLocale);
    return appLocale;
};

export const getLanguageCodeByCountryCode = countryCode => {
    switch (countryCode) {
        case appLocales.CS: {
            return 'CS';
        }
        case appLocales.EN: {
            return 'EN';
        }
        case appLocales.SK: {
            return 'SK';
        }
        case appLocales.HU: {
            return 'HU';
        }
        case appLocales.PL: {
            return 'PL';
        }
        case appLocales.ES: {
            return 'ES';
        }
        case appLocales.PT: {
            return 'PT';
        }
        default:
            return '';
    }
};
