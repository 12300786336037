import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Logo } from '../../components/basics/Logo';
import { InteractiveButton } from '../../components/buttons';
import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { CurrentStudioName } from '../../components/ui/CurrentStudioName';
import { currentPublicViewStudioVar } from '../../lib/apollo/cache';
import { commonRoutes } from '../../router/constants';
import { LOGIN_QUERY_PARAM_FROM_LOCATION } from '../../util/calendar';
import { HeaderMobileWrapper } from './HeaderMobileWrapper';

export const PublicHeader = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const currentPublicViewStudio = useReactiveVar<any>(currentPublicViewStudioVar);

    return (
        <HeaderMobileWrapper isMobile={isMobile}>
            <div className="public-app-menu">
                <div className="app-headline">
                    <div className="studio-logo">
                        <Logo width={30} color="#888bbb" />
                    </div>
                    {!isMobile && currentPublicViewStudio?.name && (
                        <>
                            <CurrentStudioName
                                studioName={currentPublicViewStudio.name}
                                studioDomain={currentPublicViewStudio.domain}
                            />
                        </>
                    )}
                </div>
                <div className="app-headline--right">
                    <InteractiveButton
                        type="link"
                        onClick={() =>
                            (window.location.href = currentPublicViewStudio?.domain
                                ? `/${commonRoutes.LOGIN}?${LOGIN_QUERY_PARAM_FROM_LOCATION}=${pathname}`
                                : `/${commonRoutes.LOGIN}`)
                        }
                        value={t('header.login')}
                    />
                    <LanguageSwitcher />
                </div>
            </div>
        </HeaderMobileWrapper>
    );
};
