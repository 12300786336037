import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/sk';
import 'moment/locale/hu';
import 'moment/locale/pl';
import 'moment/locale/es';
import 'moment/locale/pt-br';

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ContentLoading } from '../components/ui/ContentLoading';
import { APPLICATION_SUBDOMAIN } from '../constants';
import { lazyRetry } from '../util/codeSplitting';

const CommonRoutesSwitch = React.lazy(() => lazyRetry(() => import('./CommonRoutesSwitch'), 'commonRoutesSwitch'));

const AppRouter = React.lazy(() => lazyRetry(() => import('./AppRouter/AppRouter'), 'appRouter'));

export const PublicAppRouter = () => {
    return (
        <React.Suspense fallback={<ContentLoading />}>
            <Switch>
                <Route path={`/${APPLICATION_SUBDOMAIN}`}>
                    <AppRouter />
                </Route>
                <CommonRoutesSwitch />
            </Switch>
        </React.Suspense>
    );
};
