import countryTimezones from 'countries-and-timezones';

const problematicZones = {
    CET: 'Europe/Prague',
    CEST: 'Europe/Prague',
    PST: 'America/Los_Angeles',
    PDT: 'America/Los_Angeles',
    MST: 'America/Denver',
    MDT: 'America/Denver',
    CST: 'America/Chicago',
    CDT: 'America/Chicago',
    EST: 'America/New_York',
    EDT: 'America/New_York',
    GMT: 'Etc/GMT',
    BST: 'Europe/London',
    HST: 'Pacific/Honolulu',
    AKST: 'America/Anchorage',
    AKDT: 'America/Anchorage',
    AST: 'America/Halifax',
    ADT: 'America/Halifax',
    IST: 'Asia/Kolkata',
    AEST: 'Australia/Sydney',
    AEDT: 'Australia/Sydney',
    ACST: 'Australia/Adelaide',
    ACDT: 'Australia/Adelaide',
    AWST: 'Australia/Perth',
    NZST: 'Pacific/Auckland',
    NZDT: 'Pacific/Auckland',
    WET: 'Europe/Lisbon',
    WEST: 'Europe/Lisbon',
    EET: 'Europe/Bucharest',
    EEST: 'Europe/Bucharest',
    SAST: 'Africa/Johannesburg',
    CXT: 'Indian/Christmas',
    CHST: 'Pacific/Guam',
    PHT: 'Asia/Manila',
    SGT: 'Asia/Singapore',
    KST: 'Asia/Seoul',
    JST: 'Asia/Tokyo',
};

export const getTimezonesByCountry = country => {
    const countryTimezonesData = countryTimezones.getCountry(country);
    return countryTimezonesData?.timezones ?? [];
};

export const getBrowserTimezone = () => {
    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Pokud je časová zóna problematická, přemapujeme ji na správný IANA identifikátor
    if (problematicZones[browserTimeZone]) {
        browserTimeZone = problematicZones[browserTimeZone];
    }

    return browserTimeZone;
};
